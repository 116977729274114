.contact {
    padding: 80px 0px;

    h1, h2 {
        text-align: center;
        margin: 20px 0 30px 0;
    }

    .ContactInfo {
        display: flex;

        form {

            flex: 2;
            padding: 10px 50px 20px 40px ;

            .inputBox {
                margin-top: 5px;

                .field {
                    width: 100%;
                    height: 40px;
                    background: transparent;
                    border: 2px solid #000;
                    outline: none;
                    border-radius: 6px;
                    padding: 10px;
                    font-size: 14px;
                }

                .message {
                    height: 100px;
                    resize: none;
                }
            }

            button {
                width: 100%;
                height: 45px;
                background: #f1575d;
                border: none;
                border-radius: 6px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                cursor: pointer;
                color: #fff;
                font-size: 16px;
                margin-top: 10px;
                font-weight: 500;
                transition: 0.5s;

                &:hover {
                    background: #eb2d32;
                }
            }
        }

        .ContactDetails {
            flex: 1;
            padding: 20px 20px 20px 50px;
            border-radius: 0 10px 10px 0;

            h2 {
                margin-bottom: 20px;
                text-align: center;
            }

            h3 {
                margin-bottom: 10px;
                margin-top: 20px;
            }

            span {
                display: flex;
                font-size: 16px;
                gap: 10px;
                margin-bottom: 5px;
            }

            .social {
                display: flex;
                gap: 20px;
                justify-content: start;
              }
        }
    }

    .locationWrap {
        background-color: #eb2d32;
        color: white;

        h2 {
            padding-top: 30px;
        }
        .location {
            display: flex;
            gap: 50px;
            padding: 10px 50px;
            
            .locationArea {
                flex: 1;

                h4 {
                    margin-top: 10px;
                }
                .locationMap {
                    overflow: hidden;
                    padding-bottom: 56.25%;
                    position: relative;
                    height: 0;

                    iframe {
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                    }

                }
            }
        }
    }

    @media screen  and (max-width: 900px) {
        .ContactInfo {
            flex-direction: column;
            justify-content: center;
        }

        .locationWrap {
            .location {
                flex-direction: column;
            }
        }
    }
}

