.store {
  min-height: 100vh;
  width: 100%;
  background-image: url('https://www.remelifestyle.in/wp-content/uploads/2019/11/blouses-boutique-business-2556295.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

  /* Adding an overlay to dim the background image */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    z-index: 1;
  }

  .middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 30px;
    color: white;
    z-index: 2;

    h1 {
      font-size: 3rem;
      margin-bottom: 20px;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    }

    hr {
      margin: auto;
      width: 40%;
      height: 2px;
      border: none;
      background-color: white;
    }
  }
}
