.navbar {
    max-height: 70px;
  
    .wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #eeeaea;
      padding: 15px 5%;
      position: fixed;
      z-index: 1000;
  
      .logo {
        flex: 0 1 20%;
        max-width: 200px;
        width: 500px;
        transform: translateY(2px);
      }
  
      ul {
        flex: 1;
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 0;
        padding: 0;

        .link {
          
          &.active {
            color: #eb2d32;
            font-weight: 1000;
            transform: scale(1.1);
            
          }

          &:hover {
            color: #eb2d32;
            transform: scale(1.1);
            transition: all 0.3s ease-in-out 0.1s;
            color: #d9ab7b;
          }

        }
  
        li {
          margin: 0 15px;
          font-size: 18px;
          cursor: pointer;
        }
      }
  
      .options {
        flex: 0 1 auto;
        display: flex;
        align-items: center;
        position: relative;
  
        .open {
          display: none;
          cursor: pointer;
          margin-right: 25px;
        }
  
        .icon-container {
          cursor: pointer;
          position: relative;
          display: flex;
          align-items: center;
          margin-left: -10px;
  
          .heartsvg {
            margin-right: 30px; 
          }
  
          .whishlist-span,
          .cart-span {
            background-color: #eb2d32;
            position: absolute;
            top: 15px;
            width: 25px;
            height: 25px;
            color: #fff;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
          }
  
          .cart-span {
            right: -13px;
          }
  
          .whishlist-span {
            right: 20px;
          }
        }
      }
  
      @media screen and (max-width: 980px) {
        .logo {
          flex: 0 1 35%;
        }
  
        .navLinks {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          position: fixed;
          top: 70px;
          right: -60%;
          height: 100vh;
          width: 60%;
          background-color: #eeeaea;
          padding: 10px;
          z-index: 10000;
          box-shadow: -10px 10px 20px rgba(0, 0, 0, 0.1);
          transition: .3s ease-in-out;
  
          li {
            margin: 15px;
            padding-right: 10px;
            
          }
        }
  
        .navLinks.active {
          right: 0;
          transition: .3s ease-in-out;
  
          svg {
            display: block;
            position: fixed;
            right: 20px;
            top: 20px;
          }
        }
  
        .options {
          .open {
            display: flex;
          }
        }
      }
  
      @media screen and (max-width: 480px) {
        .logo {
          flex: 0 1 50%;
          width: 150px;
        }
      }
    }
  }
  