.eventPage {
    padding: 80px 60px;
    color: #333;
    line-height: 1.6;
  
    h1 {
      font-size: 28px;
      font-weight: 700;
      text-align: center;
      color: #eb2d32;
      margin-bottom: 30px;
      text-transform: capitalize;
    }
  
    img {
      aspect-ratio: 16/9;
      width: 100%;
      max-width: 700px;
      height: auto;
      display: block;
      margin: 0 auto 30px;
      border-radius: 10px;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease, box-shadow 0.3s ease;
  
      &:hover {
        transform: scale(1.05);
        box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
      }
    }
  
    .Summary {
      font-size: 18px;
      color: #555;
      max-width: 900px;
      margin: 0 auto;
      text-align: justify;
      padding: 10px;
      border-radius: 8px;
    }
  }
  
  @media screen and (max-width: 1000px) {
    .eventPage {
      padding: 80px 30px 40px 30px;
  
      h1 {
        font-size: 25px;
      }
  
      img {
        max-width: 100%;
        height: auto;
      }
  
      .Summary {
        font-size: 16px;
        padding: 15px;
      }
    }
  }
  
  @media screen and (max-width: 500px) {
    .eventPage {
      padding: 80px 20px 30px 20px;
  
      h1 {
        font-size: 20px;
      }
  
      img {
        height: auto;
      }
  
      .Summary {
        font-size: 13px;
        padding: 10px;
      }
    }
  }