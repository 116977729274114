.whatsapp {
    position: fixed;
    bottom: 5px;
    right: 15px;
    z-index: 100000;
    
    svg {
        border-radius: 5px;
    }
}
