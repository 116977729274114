.OuickLinks {
    display: flex;
    padding: 20px 20px 60px 20px;
    background: #d9ab7b;
    margin-bottom: 20px;

        .linkContainer {

            width: 100%;

            h3 {
                margin-bottom: 10px;
            }

            .inlineC {
                display: flex;
                align-items: center;
                margin-bottom: 5px;
                width: 100%;

                span {
                    margin-left: 5px;
                }
            }

            .social {
                display: flex;
                gap: 10px;
            }

            p {
                font-size: 14px;
            }
        }


    ul {
        list-style: none;

        li {
            margin-bottom: 5px;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    @media screen and (max-width: 680px) {
        flex-direction: column;
        padding: 20px;

        .linkContainer {
            margin-bottom: 20px;

        }

        span {
            font-size: 80%;
        }

    }

}