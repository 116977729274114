.about {
  padding: 150px 0;

  .about-section {
    padding: 0 40px;

    .row {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 40px;

      .flex1 {
        flex: 2.5;
        padding-left: 40px;

        h2 {
          text-align: center;
          margin-bottom: 20px;
        }

        p {
          font-size: 1.15rem;
          margin-bottom: 40px;
          line-height: 30px;
          opacity: 0;
          transform: translateY(20px);
          animation: fadeInUp 0.8s ease-out forwards;
          animation-delay: 0.3s;

          span {
            font-weight: bold;
            color: #eb2d32;
          }
        }

        .social {
          display: flex;
          gap: 30px;
          justify-content: center;
        }

      }

      .flex2 {
        flex:1;
        max-width: 48%;
        box-sizing: border-box;

        img {
          display: block;  
          max-height: 400px;
          border-radius: 10px;
        }

      }
  }
  }

  .vision-sector {
    position: relative;
    background-image: url('../../images/vidumini1.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px;
    color: white;
    position: relative;
    isolation: isolate;

    .vision-content {
      display: flex;
      justify-content: center;
      flex-direction: column; 
      max-width: 1200px;
      margin: 0 auto;

      .left-column {
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center; 

        h2 {
          font-size: 2em;
          margin-bottom: 20px;
        }

        .vision-statement {
          font-size: 1.4em;
          margin-bottom: 30px;
        }
      }

      .right-column {
        .vision-items {
          display: grid;
          grid-template-columns: 1fr;
          gap: 20px;

          @media (min-width: 768px) {
            grid-template-columns: 1fr 1fr;
          }
        }

        .vision-item {
          display: flex;
          align-items: flex-start;
          margin-bottom: 20px;
          border: 4px solid #d9ab7b;
          padding: 10px;
          border-radius: 10px;

          .icon {
            font-size: 1.8em;
            margin-right: 15px;
          }

          .text {
            h3 {
              font-size: 1.4em;
              margin-bottom: 10px;
            }

            p {
              font-size: 0.9em;
              margin: 0;
            }
          }
        }
      }
    }
  }

  .vision-sector::after {
    content: '';
    position: absolute;
    inset: 0;
    background: #eb2d32;
    z-index: -1;
    opacity: 0.88;
  }

  .spacer {
    aspect-ratio: 900/150;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .layer1 {
    background-image: url('../../images/RedBgUp.svg');
  }

  .layer2 {
    background-image: url('../../images/RedBgDown.svg');
  }

  @media (max-width: 768px) {
    padding: 90px 0;
  
    .about-section {
      padding: 0;
      
      .row {
        flex-direction: column;
  
        .flex1 {
          margin-bottom: 0; 
          padding-left: 10px;
          
          p {
            margin-bottom: 10px;
          }
        }
  
        .flex2 {
          width: 100%;
          margin-bottom: 20px;
          padding-left: 10%;
        }
      }
    }
  
    .vision-sector {
      .vision-content {
        .left-column {
          margin-bottom: 20px;
  
          h2 {
            font-size: 1.8em;
          }
  
          .vision-statement {
            font-size: 1.2em;
            margin-bottom: 20px;
          }
        }
  
        .right-column {
          .vision-item {
            padding: 8px;
  
            .icon {
              font-size: 1.6em;
            }
  
            .text {
              h3 {
                font-size: 1.2em;
              }
  
              p {
                font-size: 0.85em;
              }
            }
          }
        }
      }
    }
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}