.cartList {
    position: fixed;
    right: 10px;
    top: 65px;
    width: 300px;
    background-color: #eeeaea;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 500;
  
    h1 {
      font-size: 20px;
      margin: 20px;
      text-align: center;
    }
  
    .item {
      display: flex;
      align-items: center;
      padding: 10px 20px;
      border-bottom: 1px solid #ddd;
  
      img {
        width: 50px;
        height: 60px;
        object-fit: cover;
        margin-right: 10px;
        margin-top: 10px;
      }
  
      .detail {
        flex: 1;
  
        h1 {
          font-size: 16px;
          margin: 0;
        }
  
        p {
          margin: 5px 0;
          font-size: 14px;
          color: #777;
        }
      }
  
      span {
        cursor: pointer;
        color: #eb2d32ed;
        font-size: 14px;
      }
    }
  
    .totalprice {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      border-top: 1px solid #ddd;
      font-size: 18px;
    }
  
    button {
      width: 100%;
      padding: 10px;
      background-color: #d9ab7b;
      border: 1px solid #a88734;
      color: #111;
      font-size: 16px;
      cursor: pointer;
      text-align: center;
    }
  
    .reset {
      text-align: center;
      padding: 10px;
      color: #eb2d32;
      cursor: pointer;
    }
  }
  