.footer {
    background-color: #eb2d32;
    color: white;
    text-align: center;
    padding: 0.5% 2%; 
    position: absolute;
    width: 100%;
    font-size: 14px; 
    bottom: 0; 

    .footer-content {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;  
    }

    .credit {
        margin-top: 5px;
        font-size: 12px;  
        
        a {
            color: #ffffff;
            text-decoration: underline;
            &:hover {
                color: #d9ab7b;
            }
        }
    }

    @media screen and (max-width: 768px) {
        font-size: 13px;
        padding: 0.5% 1.5%;
    }

    @media screen and (max-width: 480px) {
        font-size: 12px;
        padding: 1% 1%;
    }
}
