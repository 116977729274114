.errorContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  #oops {
    font-size: 20vh;
    margin-top: 25vh;
    color: #d9ab7b;
  }
  #error {
    margin: 1vh;
    font-size: 4vh;
    color: #eb2d32;
  }

  button {
    cursor: pointer;
    margin-top: 15px;
    display: inline-flex;
    gap: 0.55rem;
    align-items: center;
    padding: 0.9rem 1.111rem;
    font-weight: 600;
    border-radius: 100px;
    line-height: .75;
    text-decoration: none;
    background-color: #f1575d;
    border: none;
    color: white;
    transition: .2s;

    &:hover {
        background-color: #eb2d32;
    }
}

  @media screen and (max-width: 500px) {
    
    #oops {
      font-size: 7vh;
    }

    #error {
      font-size: 3vh;
    }
  }
}
