* { 
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

.App {
    width: 100vw;
    background-color: #f9f4ed;
    position: relative;
    min-height: 100vh;

    .link {
        color: inherit;
        text-decoration: none;
    }
}