.branches {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
  
    h1 {
      text-align: center;
    }
  
    .branchArea {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      max-width: 800px;
  
      .branchContainer {
        width: 48%;
        border: 3px solid #d9ab7b;
        margin: 10px;
        border-radius: 10px;
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 240px;
  
        .branchHeader {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          margin-bottom: 10px;

          svg {
            color: #eb2d32;
          }
  
          h2 {
            text-align: center;
            margin: 10px 0 0;
            color: #eb2d32;
          }
        }
  
        .building {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 10px;
        }
  
        .inlineC {
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          width: 100%;
  
          svg {
            margin-right: 10px;
          }
  
          span {
            text-align: left;
          }
        }
      }
    }
  
    @media screen and (max-width: 480px) {
      .branchArea {
        flex-direction: column;
        align-items: center;
  
        .branchContainer {
          width: 80%;
          margin: 10px 0;
        }
      }
    }
  }
  