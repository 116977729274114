.events {
    padding: 100px 10%;
  
    h1 {
      text-align: center;
      margin-bottom: 30px;
    }
  
    .row {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;
      margin-top: 20px;
    }
  
    .col {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
    }
  
    @media screen and (max-width: 800px) {
      padding: 80px 5%;
  
      .row {
        grid-template-columns: 1fr;
      }
    }
  }
  