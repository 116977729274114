.CardWrap {   
    container-type: inline-size;

    .EventCard {
        display: flex;
        gap: 30px; 
        border-radius: 10px;
        box-shadow: 6px 7px 23px rgba(0, 0, 0, .25);
        padding: 10px;
        background-color: #d9ab7b;
        min-height: 400px;

        .CardImgWrap, .CardContent {
            flex: 1;
        }

        .CardImg img {
            position: relative;
            aspect-ratio: 16/12;
            width: 100%;
            min-height: 100%;
            border-radius: 10px;
        }

        .CardContent {
            padding: 30px 0 30px 0;
            display: flex;
            flex-direction: column;
            justify-content: center;

            h3 {
                margin-bottom: 20px;
            }
        }

        .CardButton button {
            cursor: pointer;
            margin-top: 15px;
            display: inline-flex;
            gap: 0.55rem;
            align-items: center;
            padding: 0.555rem 1.111rem;
            font-weight: 600;
            border-radius: 100px;
            line-height: .75;
            text-decoration: none;
            background-color: #f1575d;
            border: none;
            color: white;
            transition: .2s;

            &:hover {
                background-color: #eb2d32;
            }
        }
    }
}


.small {
    flex-direction: column;
    gap: 0 !important;
    padding: 5px !important;
    min-height: 380px;
    .CardContent {
        padding: 0 10px !important;

        h3 {
            font-size: 15px;
        }


        p {
            display: none;
        }
    }
}

@media screen and (max-width: 900px) {
    .EventCard {
        flex-direction: column;
        gap: 0 !important;
        padding: 5px !important;

        .CardContent {
            padding: 0 10px !important;
        }
    }
}